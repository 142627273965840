.add-to-home-screen {
    position: fixed;
    z-index: 1000;
    bottom: 0;
    right: 0;
    width: 100%;
    max-width: 500px;
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    padding: 8px 8px 6px;
    flex-flow: column;
    box-shadow: 0px 1px 10px 0px rgb(0 0 0 / 40%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
}

.add-to-home-screen .add-to-home-screen__main {
    display: flex;
    justify-content: space-between;
}

.add-to-home-screen .add-to-home-screen__close {
    min-width: 40px;
    width: 40px;
    height: 40px;
    padding-top: 0;
    padding-right: 0;
    margin-top: 0;
}

.add-to-home-screen .add-to-home-screen__add {
    margin-left: auto;
}

.add-to-home-screen .add-to-home-screen__text {
    flex-grow: 1;
    padding-left: 8px;
    width: 100%;
    overflow: hidden;
}

.add-to-home-screen .add-to-home-screen__text .add-to-home-screen__text-title {
    margin: 0;
    font-size: 1.7em;
    font-weight: bold;
}

.add-to-home-screen .add-to-home-screen__text .add-to-home-screen__text-description {
    font-size: 1.2em;
    font-style: italic;
}

.add-to-home-screen .add-to-home-screen__text .add-to-home-screen__text-description-ios {
    font-size: 1.3em;
}

.add-to-home-screen .add-to-home-screen__text .add-to-home-screen__text-description-ios img {
    height: 1.5em;
}

.add-to-home-screen .add-to-home-screen__text .add-to-home-screen__text-description-ios {
    display: block;
}

.add-to-home-screen .add-to-home-screen__logo {
    width: 80px;
    height: 80px;
    overflow: hidden;
    flex-shrink: 0;
}

.add-to-home-screen .add-to-home-screen__logo .add-to-home-screen__logo-img {
    height: 80px;
}

@media only screen and (min-width: 768px) {
    .add-to-home-screen {
        width: auto;
    }
}