.context {
    height: inherit;
}

.context > .context__bar {
    display: flex;
    justify-content: flex-end;
}

.context.ios-fullscreen > .context__bar {
    background-color: #000;
}
.ios-fullscreen {
    width: 100vw;
    position: fixed;
    z-index: 10000;
    height: calc(100% - 46px);
    right: 0;
    top: 0;
}